<h2 mat-dialog-title>Template instructions:</h2>
<mat-dialog-content>
  <div class="instruction-info">
    <i>
      Before submitting the file, please check to make sure the below criteria
      are being met to avoid delays in processing.
    </i>
  </div>
  <ul class="no-bullets">
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        Account_group represents business_unit for NAF and carrier_id for OTR
      </div>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        Account_number represents customer_id for NAF and ar_number for OTR
      </div>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        For limit changes, ensure the update_type has been entered as CLD or CLI
      </div>
      <ul>
        <li>
          Note: NAF & OTR Suspensions are not supported via RAG Bulk Actions
          bullet
        </li>
        <li>
          For CLD limit changes, ensure there is a template_type entered as LDM,
          LDU, or LDP
          <ul>
            <li>
              For CLD, ensure there are between 1 - 4 reason codes entered
            </li>
          </ul>
        </li>
        <li>
          For CLI limit changes, ensure there is a template_type entered as CLI
        </li>
      </ul>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        If <b>NAF</b> Inactive/Dormant account CLD, enter the following entries
        for each inactive/dormant account:
      </div>
      <ul>
        <li>template type “INACTIVITY”</li>
        <li>reason_code_1 "INACTIVITY"</li>
        <li>credit limit amount is 1</li>
       
        <li>
          Comment must be "Account closed for inactivity - Service Alert 1802"
        </li>
      </ul>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        If <b>OTR</b> Inactive/Dormant account CLD, enter the following entries
        for each inactive/dormant account:
      </div>
      <ul>
        <li>template type “INACTIVITY”</li>
        <li>reason_code_1 "INACTIVITY"</li>
        <li>credit limit amount is 0</li>
        <li>
          Comment must be "Account closed for inactivity - Service Alert 1802"
        </li>
      </ul>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        The status column is only informational and should reflect the current
        status of the account, either ‘Active’ or ‘Inactive’
      </div>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        Ensure there are no extra spaces added at the beginning and end of any
        field (template_type, customer id, set id, comment field)
      </div>
      <ul>
        <li>
          Note: We will remove spaces at the beginning and end of any field
        </li>
      </ul>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        Ensure numerical entries are numerical only (ex: Customer ID)
      </div>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        Ensure alpha entries (ie. no numbers) are Text only (SET ID)
      </div>
    </li>
    <li>
      <div class="list-item-info">
        <mat-icon class="green">check_circle</mat-icon>
        Content errors: missing reason codes when a template type is included,
        missing customer id, etc.
      </div>
    </li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>OK</button>
</mat-dialog-actions>
